<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row class="ma-0 pa-0" style="height: calc(100vh - 50px)">
      <v-col
        cols="12"
        class="ma-0 pa-0 d-flex flex-column justify-center align-center"
      >
        <v-card elevation="0" class="pa-5">
          <div class="d-flex justify-center mb-5">
            <div class="d-flex">
              <v-img width="100px" contain src="/altamed.png"></v-img>
            </div>
          </div>
          <h1 class="text-center pa-0 pb-5">Benvenuto</h1>
          <v-text-field
            placeholder="E-mail"
            v-model="email"
            rounded
            filled
          ></v-text-field>
          <v-text-field
            placeholder="Password"
            v-model="password"
            :append-icon="passwordVisibile ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordVisibile = !passwordVisibile"
            :type="passwordVisibile ? 'text' : 'password'"
            rounded
            filled
          ></v-text-field>
          <v-card-actions class="ma-0 pa-0 d-flex justify-center">
            <v-btn
              :color="errore != '' ? 'error' : 'primary'"
              @click="accedi"
              :loading="caricamento"
              elevation="0"
              width="80%"
              rounded
            >
              Accedi
            </v-btn>
          </v-card-actions>
          <v-divider class="my-5"></v-divider>
          <v-card-text class="pa-0 text-center">
            Developed by
            <strong>TOBIN S.R.L.S.</strong>
          </v-card-text>
          <div class="d-flex justify-center mt-2">
            <div class="d-flex">
              <v-img width="30px" contain src="/TOBIN.png"></v-img>
            </div>
          </div>
        </v-card>
        <v-alert
          v-if="errore != ''"
          type="error"
          border="left"
          outlined
          transition="top"
          style="max-width: 400px"
        >
          {{ errore }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { firebaseAuth } from "@/plugins/firebase";

export default {
  data() {
    return {
      email: "",
      password: "",
      caricamento: false,
      errore: "",
      passwordVisibile: false
    };
  },
  methods: {
    async accedi() {
      const { email, password } = this;
      this.errore = "";
      if (email === "" || password === "") {
        this.errore =
          "Attenzione, sono necessarie le credenziali per accedere alla piattaforma.";
        return;
      }
      this.caricamento = true;
      try {
        await firebaseAuth.signInWithEmailAndPassword(email, password);
      } catch (error) {
        console.error(error);
        switch (error.code) {
          case "auth/user-not-found":
            this.errore =
              "Attenzione, non è stato trovato alcun utente associato all'email inserita.";
            break;
          case "auth/wrong-password":
            this.errore = "Attenzione, la password inserita non è corretta.";
            break;
          case "auth/too-many-requests":
            this.errore =
              "Attenzioni, sono stati fatti troppi tentativi d'accesso. Riprovare pìù tardi.";
            break;
          default:
            this.errore = "Errore generico, contattare l'assistenza.";
            break;
        }
      } finally {
        this.caricamento = false;
      }
    }
  },
  mounted(){
    if (this.$route && this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  }
};
</script>
